import { HomePage } from 'containers/HomePage'
import { refreshRateSSR } from 'data/globalsVariables'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

export const getStaticProps = async ({ locale }: any) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'account',
        'home',
        'header',
        'common',
        'modals',
        'tracks',
        'plans',
      ])),
    },
    /** update static page every day (check is done when page is loaded) */
    revalidate: refreshRateSSR,
  }
}

export default HomePage
