import React, { SyntheticEvent } from 'react'
import {
  Button as MaterialButton,
  ButtonProps,
  styled,
  Theme,
  useMediaQuery,
} from '@mui/material'

interface StyledButtonProps extends ButtonProps {
  widthButton: number | string
  heightButton: number | string
  fontSizeButton: number | string
  gradient?: string
  colorButton?: string
  variant?: 'contained' | 'outlined' | 'text'
}

const Button = styled(MaterialButton, {
  shouldForwardProp: prop =>
    prop !== 'widthButton' &&
    prop !== 'heightButton' &&
    prop !== 'fontSizeButton' &&
    prop !== 'gradient' &&
    prop !== 'variant' &&
    prop !== 'colorButton',
})<StyledButtonProps>(
  ({
    gradient,
    widthButton,
    heightButton,
    fontSizeButton,
    colorButton,
    variant,
    theme,
  }) => ({
    height: heightButton,
    width: widthButton,
    fontSize: fontSizeButton,
    fontFamily: `'Biennale', sans-serif`,
    fontWeight: 700, // heightButton === 36 ? 400 : 700,
    borderRadius: '10px',
    padding: 0,
    textTransform: 'none',
    color: colorButton || theme.palette.common.white,
    ...(variant === 'outlined' && {
      border: `2px solid ${theme.palette.common.white}`,
    }),
    background: 'rgb(0,0,0,0)',
    ...(variant === 'contained' && {
      backgroundImage:
        gradient ||
        'linear-gradient(90deg, rgba(252,92,102,1) 0%, rgba(247,133,85,1) 100%)',
    }),
    opacity: 1,
    transition: 'opacity 0.3s cubic-bezier(0, 0, 0, 1) 0s',
    ':hover': {
      opacity: 0.85,
    },
  }),
)

type SongStarterButtonTypes = {
  onClick: (event: SyntheticEvent) => void
  children: React.ReactNode
  fullWidth?: boolean
  rgb?: string
  type?: 'button' | 'reset' | 'submit' | undefined
  variant?: 'contained' | 'outlined' | 'text'
  white?: boolean
  gradient?: string
} & ButtonProps

export const SongStarterButton = ({
  onClick,
  children,
  size = 'medium',
  variant = 'contained',
  fullWidth = false,
  white = false,
  gradient: gradientOverwrite,
  type,
  ...props
}: SongStarterButtonTypes) => {
  const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  let widthButton, heightButton, fontSizeButton, gradient, color
  switch (size) {
    case 'small':
      widthButton = fullWidth ? '100%' : downSM ? 110 : 120
      heightButton = downSM ? 32 : 36
      fontSizeButton = downSM ? 13 : 14
      break
    case 'medium':
      widthButton = fullWidth ? '100%' : downSM ? 230 : 240
      heightButton = downSM ? 40 : 46
      fontSizeButton = downSM ? 15 : 16
      break
    case 'large':
      widthButton = fullWidth ? '100%' : downSM ? 180 : 200
      heightButton = downSM ? 44 : 50
      fontSizeButton = downSM ? 16 : 18
      break
  }
  if (white) {
    gradient =
      'linear-gradient(70deg, rgba(242,242,242,1) 0%, rgba(206,206,206,1) 100%)'
    color = variant === 'outlined' ? '#fff' : 'rgb(0,0,0)'
  }
  if (props.disabled) {
    gradient =
      'linear-gradient(70deg, rgba(242,242,242,0.2) 0%, rgba(206,206,206,0.2) 100%)'
    color = 'rgb(48,48,48)'
  }
  if (gradientOverwrite) {
    gradient = gradientOverwrite
  }
  return (
    <Button
      {...props}
      onClick={onClick}
      variant={variant}
      color='secondary'
      disableElevation
      type={type}
      widthButton={widthButton}
      heightButton={heightButton}
      fontSizeButton={fontSizeButton}
      colorButton={color}
      gradient={gradient}
    >
      {children}
    </Button>
  )
}
