import Head from 'next/head'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { Container, Typography, Grid, styled, Link } from '@mui/material'
import { SongStarterHeader } from 'components/SongStarterHeader'
import { Footer } from 'components/Footer'
import { SongStarterButton } from 'components/SongStarterButton'

const HeaderContainer = styled(Container)(({ theme }) => ({
  position: 'relative',
  height: 600,
  [theme.breakpoints.down('md')]: {
    height: 560,
  },
  [theme.breakpoints.down('sm')]: {
    height: '85vh',
    minHeight: 400,
  },
}))
const ContentOverlay = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  zIndex: 1,
}))
const BackgroundImage = styled('div')(() => ({
  position: 'relative',
  width: '100%',
}))

export const HomePage = () => {
  const { push } = useRouter()

  return (
    <>
      <Head>
        <title>SongStarter</title>
        <link rel='icon' href='/favicon.ico' />
        <style type='text/css'>{`html { scroll-behavior: smooth; }`}</style>
      </Head>
      <SongStarterHeader />
      {/** PAGE HEADER */}
      <HeaderContainer id='home' maxWidth={false} disableGutters>
        <ContentOverlay
          sx={{
            padding: '0px 8px',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant='h1'
            align='center'
            sx={{
              whiteSpace: 'pre-line',
              fontWeight: 700,
              marginBottom: '16px',
            }}
          >
            We Moved SongStarter To 'ProducerKits.com'!
          </Typography>
          <Typography
            variant='h1'
            align='center'
            sx={{
              whiteSpace: 'pre-line',
              fontWeight: 700,
              marginBottom: '16px',
            }}
          >
            We Are Building Something New Here..
          </Typography>

          <Link
            variant='h6'
            fontWeight={500}
            color='textSecondary'
            align='center'
            href='https://www.producerkits.com/'
            sx={{ margin: '16px 0px' }}
          >
            Go to ProducerKits.com to continue using your SongStarter account
          </Link>

          <Typography
            variant='h4'
            align='center'
            sx={{
              whiteSpace: 'pre-line',
              marginBottom: '32px',
              marginTop: '16px',
            }}
          >
            {`Still have some questions or \n downloads you need?`}
          </Typography>

          <Grid container justifyContent='center'>
            <Grid item>
              <SongStarterButton
                variant='outlined'
                white
                onClick={() => push('/contact')}
              >
                Contact Us
              </SongStarterButton>
            </Grid>
          </Grid>
        </ContentOverlay>
        <BackgroundImage
          sx={{
            height: '100%',
            // img: {
            //   opacity: 0.4,
            // },
          }}
        >
          <Image
            alt='background'
            layout='fill'
            objectFit='cover'
            src='/background.png'
          />
        </BackgroundImage>
      </HeaderContainer>
      <Footer />
    </>
  )
}
